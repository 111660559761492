export const partnerRegions = [
  {
    id: 'asia',
    name: 'Asia',
    value: 'Asia'
  },
  {
    id: 'europe',
    name: 'Europe',
    value: 'Europe'
  },
  {
    id: 'northAmerica',
    name: 'North America',
    value: 'North America'
  },
  {
    id: 'oceania',
    name: "Oceania",
    value: 'Oceania'
  },
  {
    id: 'southAmerica',
    name: 'South America',
    value: 'South America'
  }
]

