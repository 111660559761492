export const productTypes = [
  {
    id: 'Locks',
    name: 'Locks',
    value: 'Locks'
  },
  {
    id: 'Locks+Lockers',
    name: 'Locks + Lockers',
    value: 'Locks+Lockers'
  },
  {
    id: 'Locks+Furniture',
    name: 'Locks + Furniture',
    value: 'Locks+Furniture'
  }
]

