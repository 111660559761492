import React from 'react';
import PropTypes from "prop-types";

const LiveChatButton = (props) => {

  function handleSalesChatClick () {
    window.history.pushState({}, 'bot', '?bot=sales');
    window.HubSpotConversations.clear({ resetWidget: true });
  }

  function handleSupportChatClick() {
    window.history.pushState({}, 'bot', '?bot=support');
    window.HubSpotConversations.clear({ resetWidget: true });
  }

  return (
      <>
        <a id={'liveChatButton'} href="#" onClick={props.section === 'contact' ? handleSalesChatClick : handleSupportChatClick} className={'btn btn--orange live-chat'}>{props.liveChat}</a>
      </>
  )
};

LiveChatButton.propTypes = {
  section: PropTypes.string,
  liveChat: PropTypes.string
};

export default LiveChatButton;
